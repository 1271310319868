import qs from "query-string"
import { UserFiltersAttributes } from "@/Services/Search/Types/UserFiltersAttributes"
import useSWR from "swr"
import { getPrivateApiMeFiltersPath } from "@/User/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"

export interface Params extends Record<string, any> {
  expand?: Array<UserFiltersAttributes>
}
interface Props {
  params?: Params
}

export function useUserFilters({ params }: Props) {
  const path = qs.stringifyUrl({
    url: getPrivateApiMeFiltersPath(),
    query: params,
  })

  const { data, ...rest } = useSWR<{
    code: string
    data: any
  }>(path, {
    fetcher: fetcherPrivateApiUmbrella(),
  })

  return {
    data: data?.data,
    ...rest,
  }
}
