import { SortProjectsAttributes } from "@/Services/Search/Types/SortProjectsAttributes"
import { Filters } from "@components/Filters"
import { useFilterProjects } from "@components/Project/hooks/useFilterProjects"
import {
  ArrowTrendingDownIcon,
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/24/outline"
import { SVGAlphaSortDown } from "@svg/AlphaSortDown"
import { SVGAlphaSortUp } from "@svg/AlphaSortUp"
import { SVGPhp } from "@svg/Php"
import { SVGRocket } from "@svg/Rocket"
import { find, first } from "lodash"
import React from "react"

export function SortProjects() {
  const { state, setSort } = useFilterProjects()

  function onClickSetSort({ key }: { key: string | boolean | number }) {
    const [attribute, direction]: [SortProjectsAttributes, "asc" | "desc"] =
      String(key).split(":") as [SortProjectsAttributes, "asc" | "desc"]

    setSort({
      attribute,
      direction,
    })
  }

  const SORT_FILTER_ITEMS: {
    label: React.ReactNode
    key: string
    onClick: ({ key }: { key: string | boolean | number }) => void
  }[] = [
    {
      label: (
        <>
          <BarsArrowUpIcon className="h-4 w-4" aria-hidden="true" />
          Newest
        </>
      ),
      key: "id:desc",
      onClick: onClickSetSort,
    },
    {
      label: (
        <>
          <BarsArrowDownIcon className="h-4 w-4" aria-hidden="true" />
          Oldest
        </>
      ),
      key: "id:asc",
      onClick: onClickSetSort,
    },
    {
      label: (
        <>
          <SVGAlphaSortUp className="h-4 w-4" aria-hidden="true" />
          A-Z
        </>
      ),
      key: "name:asc",
      onClick: onClickSetSort,
    },

    {
      label: (
        <>
          <SVGAlphaSortDown className="h-4 w-4" aria-hidden="true" />
          Z-A
        </>
      ),
      key: "name:desc",
      onClick: onClickSetSort,
    },
    {
      label: (
        <>
          <SVGRocket className="h-4 w-4" aria-hidden="true" />
          Google PageSpeed (High first)
        </>
      ),
      key: "latest_performance_score:desc",
      onClick: onClickSetSort,
    },
    {
      label: (
        <>
          <ChevronDoubleDownIcon className="h-4 w-4" aria-hidden="true" />
          Google PageSpeed (Low first)
        </>
      ),
      key: "latest_performance_score:asc",
      onClick: onClickSetSort,
    },

    {
      label: (
        <>
          <SVGPhp className="h-4 w-4" aria-hidden="true" />
          Highest PHP Issues
        </>
      ),
      key: "count_php_issues:desc",
      onClick: onClickSetSort,
    },
    {
      label: (
        <>
          <ArrowTrendingDownIcon className="h-4 w-4" aria-hidden="true" />
          Down first
        </>
      ),
      key: "is_currently_down:desc",
      onClick: onClickSetSort,
    },
  ]

  function getSortLabelAndIcon() {
    const sortSelected = first(state.sort.toStringArray())

    if (!sortSelected) {
      return find(SORT_FILTER_ITEMS, { key: "id:desc" }).label
    }

    const sortItem = find(SORT_FILTER_ITEMS, { key: sortSelected })

    if (!sortItem) {
      return find(SORT_FILTER_ITEMS, { key: "id:desc" }).label
    }

    return sortItem.label
  }

  const sortViewer = getSortLabelAndIcon()

  return (
    <>
      <Filters.DropdownElement
        items={SORT_FILTER_ITEMS}
        label={sortViewer}
        width="w-64"
      />
    </>
  )
}
