export const SVGRocket = ({ className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    className={className}
    style={{
      transform: "rotate(360deg)",
    }}
    fill="currentColor"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path
      d="M9 19l-4-4c0-.333.333-1 1-2l-4-1l2-3l4 1c4-6 10-8 14-8c0 4-2 10-8 14l1 4l-3 2l-1-4c-1 .667-1.667 1-2 1zm6.5-9a1.5 1.5 0 1 0 0-3a1.5 1.5 0 0 0 0 3zM4 16l1.25 1.25C4.583 17.917 4.167 18.833 4 20c1.167-.167 2.083-.583 2.75-1.25L8 20c-1.333 1.333-3.333 2-6 2c0-2.667.667-4.667 2-6z"
      fillRule="evenodd"
    />
  </svg>
)
