import { FilterProjectsAttributes } from "@/Services/Search/Types/FilterProjectsAttributes"
import { Filters } from "@components/Filters"
import { useFilterProjects } from "@components/Project/hooks/useFilterProjects"
import { useUserFilters } from "@hooks/user/useUserFilters"
import Badge from "@ui/Badge"
import { System } from "@design-system/index"
import classNames from "classnames"
import { get, isEmpty, isNil, orderBy } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { SortProjects } from "./components/SortProjects"

const FILTER_LIST = {
  labels: {
    name: "Labels",
    label: "Select Labels",
    placeholder: "Search Labels",
    icon: System.Svg.Label,
  },
  plugins: {
    name: "Plugins",
    label: "Select plugins",
    placeholder: "Search plugins",
    icon: System.Svg.Plugin,
  },
  themes: {
    name: "Themes",
    label: "Select themes",
    placeholder: "Search themes",
    icon: System.Svg.Theme,
  },
  php_versions: {
    name: "PHP Version",
    label: "Select PHP Version",
    placeholder: "Search PHP Version",
    icon: System.Svg.Php,
  },
  wordpress_versions: {
    name: "WordPress Version",
    label: "Select WordPress Version",
    placeholder: "Search WordPress Version",
    icon: System.Svg.WordPress,
  },
  hostings: {
    name: "Hostings",
    label: "Select Hostings",
    placeholder: "Search Hostings",
    icon: System.Svg.Database,
  },
  maintenance_customers: {
    name: "Clients",
    label: "Select Clients",
    placeholder: "Search Clients",
    icon: System.Svg.AddressBook,
  },
  only_error_communication: {
    name: "Synchronization",
    label: "Synchronization",
    placeholder: "",
    icon: System.Svg.Refresh,
  },
}

export function FilterProjects(
  {
    withToggleSynchronize = false,
  }: {
    withToggleSynchronize?: boolean
  } = {
    withToggleSynchronize: false,
  },
) {
  const {
    state,
    setSearchQuery,
    addFilterCondition,
    removeOneFilterCondition,
    removeManyFilterConditions,
    clearFilterConditions,
  } = useFilterProjects()

  const { data: dataFilters } = useUserFilters({
    params: {
      expand: [
        "plugin",
        "theme",
        "php_version",
        "wordpress_version",
        "hosting",
        "maintenance_customer",
        "label",
      ],
    },
  })

  const [filters, setFilters] = useState<string[]>([])

  const [lastFilterIndex, setLastFilterIndex] = useState<string>(null)
  const buttonRefs = {
    plugins: useRef<HTMLButtonElement>(),
    themes: useRef<HTMLButtonElement>(),
    php_versions: useRef<HTMLButtonElement>(),
    wordpress_versions: useRef<HTMLButtonElement>(),
    hostings: useRef<HTMLButtonElement>(),
    maintenance_customers: useRef<HTMLButtonElement>(),
    labels: useRef<HTMLButtonElement>(),
    only_error_communication: useRef<HTMLButtonElement>(),
  }

  if (!withToggleSynchronize) {
    delete buttonRefs.only_error_communication
    delete FILTER_LIST.only_error_communication
  }

  const ADD_FILTER_ITEMS: {
    label: React.ReactNode
    key: string
    onClick: () => void
  }[] = Object.keys(FILTER_LIST)
    .map((key) => {
      const Icon = FILTER_LIST[key].icon

      if (
        get(dataFilters, [key], []).length === 0 &&
        key !== "only_error_communication"
      ) {
        return null
      }

      return {
        label: (
          <div className="flex items-center gap-2">
            <Icon className="h-4 w-4" />
            {FILTER_LIST[key].name}
          </div>
        ),
        key,
        onClick: () => {
          if (filters.includes(key)) {
            return
          }

          setFilters([...filters, key])
          setLastFilterIndex(key)
        },
      }
    })
    .filter((item) => !isNil(item))

  function findItemFilterByKey({
    key,
    filters,
  }: {
    key: string | number | boolean
    filters: {
      name: string
      key: string
    }[]
  }) {
    return filters.find((item) => item.key === key)
  }

  if (withToggleSynchronize && !isNil(dataFilters)) {
    const currentTimestamp = Math.floor(new Date().getTime() / 1000)

    dataFilters.only_error_communication = [
      {
        name: "Disconnected",
        searchValue: "disconnected",
        value: currentTimestamp - 21600,
        key: "only_error_communication",
        operator: "<",
        label: "Disconnected",
      },
    ]
  }

  function getItemsByFilter({ filter }) {
    return orderBy(
      get(dataFilters, [filter], []).map((item) => {
        let label =
          item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name

        if (filter === "only_error_communication") {
          return {
            label: label,
            searchValue: item.name,
            key: item.key,
            onClick: ({ key }) => {
              addFilterCondition({
                attribute: getAttributebyFilter({
                  filter: filter,
                }),
                value: item.value,
                operator: item.operator,
              })
            },
          }
        }

        if (filter === "labels") {
          label = (
            <System.Site.Label
              size="small"
              color={item.color}
              name={item.name}
            />
          )
        }

        return {
          label: label,
          searchValue: item.name,
          key: item.key,
          onClick: ({ key }) => {
            addFilterCondition({
              attribute: getAttributebyFilter({
                filter: filter,
              }),
              value: key,
              operator: "=",
            })
          },
          renderBadge: () => {
            return (
              <>
                <Badge gray className="ml-auto !text-[10px]">
                  {item.count} Site
                  {item.count > 1 ? "s" : ""}
                </Badge>
              </>
            )
          },
        }
      }),
      ["label", "searchValue"],
      ["asc"],
    )
  }

  function getItemsSelectedByFilter({ filter }) {
    return state.filters.getConditionsByAttributeToArrayString(
      getAttributebyFilter({ filter }),
    )
  }

  function getAttributebyFilter({ filter }): FilterProjectsAttributes {
    switch (filter) {
      case "plugins":
        return "plugins.key"
      case "themes":
        return "themes.stylesheet"
      case "php_versions":
        return "warnings.php_current_version"
      case "wordpress_versions":
        return "warnings.wordpress_version"
      case "maintenance_customers":
        return "maintenanceCustomerId"
      case "hostings":
        return "hosting"
      case "labels":
        return "labels.id"
      case "only_error_communication":
        return "last_synchronization.date_with_success_timestamp"
    }
  }

  useEffect(() => {
    if (!lastFilterIndex) {
      return
    }

    buttonRefs[lastFilterIndex].current?.click()
    setLastFilterIndex(null)
  }, [filters])

  return (
    <>
      <div className="flex items-center gap-8">
        <div className="w-1/3">
          <Filters.SearchBox
            onClick={({ value }) => {
              setSearchQuery(value)
            }}
            placeholder="Search sites"
          />
        </div>
        {ADD_FILTER_ITEMS.length > 0 && (
          <div>
            <Filters.DropdownElement
              items={ADD_FILTER_ITEMS}
              index={900}
              itemsSelected={filters}
              label={
                <>
                  <System.Svg.Filter className="h-4 w-4 opacity-50" />
                  Add filter
                </>
              }
            />
          </div>
        )}
        <div className="ml-auto">
          <div className="flex items-center gap-4">
            <System.Text className="ml-8">Sort:</System.Text>
            <SortProjects />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          {
            "mt-8": filters.length > 0,
          },
          "relative flex flex-wrap items-center gap-4",
        )}
      >
        {filters.map((value) => {
          const filter = FILTER_LIST[value]

          let label = filter.label

          const itemsSelected = getItemsSelectedByFilter({
            filter: value,
          })
          if (!isEmpty(itemsSelected) && value !== "only_error_communication") {
            label = itemsSelected.reduce((acc, item) => {
              const itemFilter = findItemFilterByKey({
                key: item,
                filters: get(dataFilters, [value], []),
              })

              if (isNil(itemFilter)) {
                return acc
              }

              if (acc === "") {
                return itemFilter.name
              }

              return `${acc}, ${itemFilter.name}`
            }, "")
          }

          if (!isEmpty(itemsSelected) && value === "only_error_communication") {
            label = "Disconnected"
          }

          if (label.length > 30) {
            label = `${label.slice(0, 30)}...`
          }

          return (
            <React.Fragment key={`filter_${value}`}>
              <Filters.MenuSelect
                ref={buttonRefs[value]}
                label={label}
                placeholder={filter.placeholder}
                itemsSelected={itemsSelected}
                items={getItemsByFilter({ filter: value })}
                icon={filter.icon}
                onRemove={({ key }) => {
                  const condition = state.filters.getCondition({
                    attribute: getAttributebyFilter({
                      filter: value,
                    }),
                    value: key,
                    operator: "=",
                  })

                  removeOneFilterCondition(condition)
                }}
                onClear={() => {
                  removeManyFilterConditions(
                    state.filters.getConditionsByAttribute(
                      getAttributebyFilter({
                        filter: value,
                      }),
                    ),
                  )

                  setFilters(filters.filter((item) => item !== value))
                }}
              />
            </React.Fragment>
          )
        })}
        {filters.length > 0 && (
          <>
            <Filters.DropdownElement
              items={ADD_FILTER_ITEMS}
              itemsSelected={filters}
              buttonStyle="secondary"
              index={800}
              label={<>Add filter</>}
            />
            <System.Button.Default
              type="button"
              className="ml-auto"
              size="small"
              onClick={clearFilterConditions}
            >
              <System.Svg.FilterOff className="h-4 w-4 opacity-50" />
              Clear all filters
            </System.Button.Default>
          </>
        )}
      </div>
    </>
  )
}
